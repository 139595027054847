import {routeDict} from '~/router'

let menu = [
    // {
    //     icon: 'fas fa-store-alt', text: 'Order', regex: /^\/(orders|import)/,
    //     items: [
    //         {icon: 'mdi-send', text: 'Mail Order', route: routeDict['mobile-view-order-list']},
    //         {icon: 'mdi-receipt', text: 'Order List', exact: true, route: routeDict['view-order-list']},
    //         // {icon: 'mdi-truck', text: 'Import Waybill', route: routeDict['import-waybill-numbers']}
    //     ]
    // },
    // {icon: 'mdi-send', text: 'Mail Order', route: routeDict['mobile-view-order-list']},
    {
        icon: 'fas fa-store-alt', text: 'Shop', regex: /^\/(shop|shop-item)/,
        items: [
            {icon: 'fas fa-store', text: 'Item List', route: routeDict['shop-items']},
            {icon: 'fas fa-receipt', text: 'Purchases', route: routeDict['shop-purchase-records']},
            {icon: 'fas fa-receipt', text: 'Mobile Purchases', route: routeDict['mobile-shop-purchase-records']}
        ]
    }
]

export default menu
